import { AudienceSVG, CubeSVG, FrameStackSVG, GlobeSVG, MergeSVG, TimeSVG, TraitSVG } from "src/ui/icons/new-icons";

export enum FilterOption {
  All = "ALL",
  Property = "Property",
  Relation = "Relation",
  Event = "Event",
  Audience = "Audience",
  Trait = "Trait",
}

export enum MetadataType {
  MergedColumn = "MergedColumn",
  Event = "Event",
  Relation = "Realtion",
}

export type FilterPopoverUI = {
  label: string;
  description: string;
  color: string;
  icon: JSX.Element;
  type: FilterOption;
};

export const GlobalColumn = {
  label: "All filters",
  description: "View all available filters",
  icon: GlobeSVG(),
  color: "forest.400",
  isSelected: true,
  type: FilterOption.All,
};

export const PropertyColumn: FilterPopoverUI = {
  label: "Properties",
  description: "Filter by a person’s attributes",
  icon: CubeSVG(),
  color: "electric.400",
  type: FilterOption.Property,
};

export const RelationColumn: FilterPopoverUI = {
  label: "Relations",
  description: "Filter by a relationship to other entities",
  icon: FrameStackSVG(),
  color: "success.border",
  type: FilterOption.Relation,
};

export const EventColumn: FilterPopoverUI = {
  label: "Events",
  description: "Filter by an event they’ve performed",
  icon: TimeSVG(),
  color: "cyan.400",
  type: FilterOption.Event,
};

export const AudienceColumn: FilterPopoverUI = {
  label: "Audiences",
  description: "Filter by whether they’re included in another audience",
  icon: AudienceSVG(),
  color: "ocean.400",
  type: FilterOption.Audience,
};

export const TraitColumn: FilterPopoverUI = {
  label: "Custom traits",
  description: "Filter by your organization’s custom attributes",
  icon: TraitSVG(),
  color: "warning.border",
  type: FilterOption.Trait,
};

export const MetadataIcons = {
  [MetadataType.MergedColumn]: MergeSVG(),
  [MetadataType.Event]: EventColumn.icon,
  [MetadataType.Relation]: RelationColumn.icon,
};

export const TABS = [GlobalColumn, PropertyColumn, RelationColumn, EventColumn, AudienceColumn, TraitColumn];

export const PADDING = 2;
export const GAP = 2;
export const MAX_INPUT_WIDTH_PX = 312;

export const MAX_POPOVER_HEIGHT_PX = TABS.length * 64 + PADDING * 4 * 2;
export const TAB_SECTION_WIDTH_PX = 304;
export const CONTENT_SECTION_WIDTH_PX = 400;
